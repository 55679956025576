<template>
  <div class="MyOrder">
    <div class="BigBox">
      <div class="Title">我的订单</div>
      <div class="MyFlex">
        <div class="AllOrders">所有订单</div>
        <div class="wide"></div>
      </div>
      <!-- <div class="Header">
        <div class="Input">
          <img src="../../../assets/PersonalCenter/search.png" alt="">
          <input type="text" placeholder="请输入商品标题搜索">
        </div>
      </div> -->
      <div class="Content">
        <!-- 表头 -->
        <div class="MeterHead">
          <div style="flex: 4.6;">商品</div>
          <div style="flex: 1;margin-left:2vw;">单价</div>
          <div style="flex: 1;">数量</div>
          <div style="flex: 2;">商家操作</div>
          <div style="flex: 1;">金额</div>
          <div style="flex: 2;">交易状态</div>
          <div style="flex: 4;">交易操作</div>
        </div>
        <!-- 表内容 -->
        <div class="TableContent" @mousewheel="MyScroll($event)" ref="personDom">
          <div class="MeterContent" v-for="(item, index) in CommList" :key="index">
            <div class="ContentName">
              <span>{{ item.createTime }}</span>
              <span>订单号:{{ item.orderNo }}</span>
              <div v-for="(items, index) in item.items" :key="index">
                <span>{{ items.categoryName }}</span>
              </div>
            </div>
            <div class="OrderContent" v-for="(items, index) in item.items" :key="index">
              <div style="flex: 5;justify-content: flex-start;">
                <img :src="items.goodsImg" style="margin: 0 2vw;width: 8vw;height: 12vh;">
                <div style="height: 80%;align-items: flex-start;color:#FFFFFF;">{{ items.goodsName }}</div>
              </div>
              <div style="flex: 1;flex-direction: column;color:#FFFFFF;">
                <p>￥{{ items.price / 100 }}</p>
              </div>
              <div style="flex: 1;color:#FFFFFF;">
                <p>{{ items.productNum }}</p>
              </div>
              <div style="flex: 2;flex-direction: column;color:#FFFFFF;" @click="centerDialogVisible = true">
                <p @click="apply()" style="cursor: pointer;">申请售后</p>
                <p style="cursor: pointer;">联系卖家</p>
              </div>
              <div style="flex: 1;color: #E92626;">
                <p>￥{{ items.orderAmount / 100 }}</p>
              </div>
              <div style="flex: 2;color:#FFFFFF;">卖家待发货</div>
              <div style="flex: 4;">
                <div style="width: 30%;justify-content: space-between;color:#FFFFFF;">
                  <div @click="Delete(item.orderId)">删除</div>
                  <div>结算</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="maskOne" @click="masksCloseFun" v-if="videoStateOne == true"></div>
    <div class="MyPop" v-if="videoStateOne == true">
      <!-- 关闭箭头 -->
      <div style="width:90%;display: flex;justify-content: flex-end;margin-top:2vh;">
        <div class="MyArrows" @click="arrows()"> <img src="../../../assets/PersonalCenter/MyArrows.png" alt=""
            style="width:100%;height: 100%;"> </div>
      </div>
      <!--提示信息 -->
      <div class="MyHint">商城业务暂未完善、请联系管理员/商家进行处理</div>
      <div class="MyHintOne">管理员邮箱∶2334445555</div>
      <div class="MyHintTwo">商家电话∶2334445555</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      // 提交信息栏
      centerDialogVisible: false,
      videoStateOne: false,
      CommList: [],
      total: null,
      Form: {
        // 每页条数
        pageSize: 3,
        // 页码
        pageNum: 1,
      },
      orderTher: [],
      orderFour: [],
    }
  },
  created() {
    this.IndentList()
  },
  mounted() {
    //商品列表
    this.$refs.personDom.addEventListener('scroll', this.MyScroll, true);
  },
  destroyed() {
    this.$refs.PersonDomOne.addEventListener('scroll', this.MyScroll, true);
    //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题 
  },
  methods: {
    // 点击申请售后
    apply() {
      this.videoStateOne = true;
    },
    // 点击关闭
    arrows() {
      this.videoStateOne = false;
    },
    // 订单列表
    IndentList() {
      var axios = require("axios");
      const data = {
        pageSize: this.Form.pageSize,
        pageNum: this.Form.pageNum,
      };
      var config = {
        method: "post",
        url: this.$Schttp + "/vtp/app/order/page",
        headers: {
          Authorization: "Bearer " + this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config).then((res) => {
        if (res.data.code == 200) {
          const comm = res.data.rows;
          this.CommList.push(...comm);
          this.total = res.data.total;

        }
      });
    },
    MyScroll(e) {
      let h = e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight
      if (h > -4) {

        if (this.Form.pageNum * this.Form.pageSize >= this.total) {
        } else {
          this.Form.pageNum++
          this.IndentList()
        }

      }

    },
    // 订单列表更新
    IndentListOne() {
      var axios = require('axios');
      var config = {
        method: 'post',
        url: this.$Schttp + '/vtp/app/order/page',
        headers: {
          Authorization: 'Bearer ' + this.$cookies.get("token"),
          'Content-Type': 'application/json'
        },
        data: {
          pageSize: this.Form.pageSize,
          pageNum: this.Form.pageNum,
        }
      };
      axios(config)
        .then((res) => {
          if (res.data.code == 200) {
            this.CommList = res.data.data
          }

        })
        .catch(function (error) {
        });
    },
    // 删除订单
    Delete(id) {
      var axios = require('axios');
      var config = {
        method: 'DELETE',
        url: this.$Schttp + '/vtp/app/order/delete/' + id,
        headers: {
          Authorization: 'Bearer ' + this.$cookies.get("token"),
          'Content-Type': 'application/json'
        },
        data: {
          orderId: id
        }
      };
      axios(config)
        .then((res) => {
          if (res.data.code == 200) {
            this.IndentListOne()
            this.$message({
              showClose: true,
              message: '删除成功',
              type: "success"
            });
          }
        })
        .catch(function (error) {
        });
    }
  },
}
</script>

<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}

.MyOrder {
  width: 99%;
  height: 99%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .BigBox {
    width: 94%;
    height: 94%;
    display: flex;
    flex-direction: column;

    .Title {
      color: rgba(255, 255, 255, 1);
      font-size: 2vw;
      margin-left: 2vw;
      margin-top: 2vh;
    }

    .MyFlex {
      margin-left: 2vw;
      margin-top: 3vh;
      width: 7%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .AllOrders {
        font-size: 2.5vh;
        font-weight: 200;
      }

      .wide {
        width: 47%;
        margin-top: 0.5vh;
      }
    }

    .Header {
      display: flex;
      width: 90%;
      margin: 2vh;
      margin-left: 2.19vw;
      justify-content: space-between;
      align-items: center;

      .Input {
        display: flex;
        border: 1px solid #B7B7B7;
        width: 35vw;
        height: 5vh;
        align-items: center;

        input {
          border: none;
          height: 100%;
          width: 90%;
          font-size: 2vh;
          background-color: rgba(255, 255, 255, 0);
          color: rgba(173, 173, 173, 1);
          text-indent: 0.5vw;
          /*placeholder文字居中*/
        }

        input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: rgba(173, 173, 173, 1);
        }

        input:focus {
          outline: none;
        }

        img {
          height: 50%;
          margin: 0 1.5vh;
        }
      }
    }

    .Content {
      display: flex;
      width: 90%;
      height: 58vh;
      margin: 2vh auto;
      flex-direction: column;

      .MeterHead {
        width: 100%;
        height: 6vh;
        background-color: #64A2FF;
        display: flex;

        div {
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          color: #FFFFFF;
        }
      }

      .TableContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 65vh;
        margin-top: 2vh;
        overflow: hidden scroll;

        .MeterContent {
          display: flex;
          flex-direction: column;
          border: 1px solid #64A2FF;
          width: 101%;
          height: 20vh;
          margin-bottom: 2vh;
          flex-shrink: 0;
       

          .ContentName {
            height: 25%;
            width: 100%;
            background-color: #64A2FF;
            display: flex;
            align-items: center;

            span {
              margin: 0 2vw;
              color: #FFFFFF;
              font-size: 2vh;
            }
          }

          .OrderContent {
            display: flex;
            height: 75%;
            width: 100%;
            font-size: 1vw;
            background-color: rgba(41, 98, 182, 0.3);

            div {
              display: flex;
              align-items: center;
              justify-content: center;

              input {
                border: 1px solid #F2F2F2;
              }

              input:focus {
                outline: 1px solid #F2F2F2;
              }
            }
          }
        }
      }
    }
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 0.3vw;
    height: 0.1vh;
    background-color: #64A2FF;
  }

  /* 滚动条上的滚动滑块 */
  ::-webkit-scrollbar-thumb {
    height: 0.3vh;
    background-color: #0F1755;
    border-radius: 32px;
  }

  .maskOne {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 888;
    background-color: #000000;
    opacity: 0.6;
  }

  .MyPop {
    width: 32%;
    height: 30%;
    background-color: white;
    border-radius: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 9999;
    .MyArrows {
      width: 6%;
      height: 3.5vh;
      cursor: pointer;
    }

    .MyHint {
      color: rgba(80, 80, 80, 1);
      font-size: 2.2vh;
      margin-top: 2vh;
    }

    .MyHintOne {
      color: rgba(138, 138, 138, 1);
      font-size: 2.2vh;
      margin-top: 4vh;
    }

    .MyHintTwo {
      color: rgba(138, 138, 138, 1);
      font-size: 2.2vh;
      margin-top: 3vh;
    }
  }
}
</style>
<style></style>